import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Col } from "react-bootstrap"

import InnerLayout from "../components/inner-layout"

import MasonryGrid from "../components/masonry-grid"

const StainedGlass = ({ data: { contentfulPage } }) => (
  <Layout>
    <SEO title="Stained Glass" />
    <InnerLayout>
      <Container>
        <Col className="text-center mx-auto mb-5" md={8}>
          <h1>Commissions</h1>
          <p>
            Tim takes great pleasure in creating individual projects for homes,
            gifts or presentations for special occasions. Many of these are
            stained glass which can be leaded or copper foiled or fused,
            tailored for the location and personal preference.
          </p>
        </Col>
      </Container>
      <MasonryGrid files={contentfulPage.images} />
    </InnerLayout>
  </Layout>
)

export const query = graphql`
  {
    contentfulPage(slug: { eq: "comissions" }) {
      name
      slug
      images {
        ... on ContentfulProductImage {
          name
          price
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default StainedGlass
